import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@Services/auth/auth.guard';
import { AttClienteComponent } from './att-cliente/att-cliente.component';
import { FaqComponent } from './faq/faq.component';
import { HealthComponent } from '@components/health/health.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '_health',
    component: HealthComponent
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages-user/pages-user.module').then((m) => m.PagesUserModule),
  },
  {
    path: 'cart',
    canActivate: [AuthGuard],
    loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
  },
  { path: 'att', component: AttClienteComponent },
  { path: 'faq', component: FaqComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
