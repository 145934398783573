<div class="page-template">
<div class="box-primary"><strong>{{ '¿Por qué SPARE PARTS COMANAI?' | translate }}</strong></div>
<div class="flex">
  <div class="end">
    <video class="video" controls poster="assets/images/Spare-Parts-video.jpg">
      <source src="assets/videos/COMANAI-SPARE-PARTS-web.mp4" type="video/mp4">
      {{ 'Su navegador no soporta la etiqueta video' | translate }}
    </video>
  </div>
  <div class="div-sup tex-jus">
    <p>Disponemos de los <strong>profesionales</strong> y el <strong>conocimiento</strong> sobre las tecnologías desplegadas en los principales sectores industriales que son necesarios para agilizar los procesos de mantenimiento y reparación de tus máquinas y líneas de producción.</p>
    <p><strong>Actualizamos constantemente</strong> nuestra base de datos, precios, stocks, fotografías y características técnicas.</p>
    <p>Somos <strong>distribuidores de las principales marcas</strong> líder en sus mercados.</p>
    <p>Ponemos a disposición del usuario todas las <strong>herramientas digitales</strong> posibles para consultar o solicitar cualquier tipo de información relacionada con piezas de recambio.</p>
    <div class="tex-jus">
      <p>{{ '¿Quieres saber más?' | translate }}</p>
      <p class="primary"><a href="mailto:comunicacion@comanai.com"> Contacta con nosotros</a> / <a [routerLink]="'/faq'">Preguntas Frecuentes</a></p>
    </div>
  </div>
</div>

<div class="box-primary">
  <strong>{{ '¿Qué vas a encontrar en SPARE PARTS COMANAI?' | translate }}</strong>
</div>

<div></div>

<div class="flex">
  <div class="flex-child">
    <div>
      <img src="assets/images/Servicio_Basico.svg" alt="Servicio" class="logo-med">
    </div>
    <div class="tex-jus">
      <p class="txt-blue">Servicio Básico: </p>
      <p>RECAMBIOS y Equipos industriales de fabricantes líderes en el mercado. </p>
      <p>RECAMBIOS de diseño y fabricación exclusiva by <span class="primary">COMANAI SMARTER ENGINEERING.</span></p>
    </div>
  </div>
  <div class="flex-child">
    <div>
      <img src="assets/images/Servicio_Premium.svg" alt="Servicio" class="logo-med">
    </div>
    <div class="tex-jus">
      <p class="txt-blue">Servicio Premium: </p>
      <p>Acceso al servicio de alojamiento de archivos en la nube que opera <span class="primary">SPARE PARTS COMANAI</span> y que permite a los usuarios almacenar y sincronizar planos y documentación técnica. </p>
      <p>Planificador de compras: Lista de Recambios Recomendados by <span class="primary">SPARE PARTS COMANAI</span> Financiación para tus compras de RECAMBIOS y equipos industriales.</p>
    </div>
  </div>
</div>
<div class="grey center-all">
<div class="flexg">
  <!-- <div class="flex-inline"> -->
  <div class="padg">
    <img src="assets/images/Ventas.svg" alt="Servicio" class="logo-med">
  </div>
  <div>
    <p class="txt-blue">Departamento de Ventas. Servicio Personalizado</p>
    <div class="tex-jus">
      <p>Disfruta de descuentos personalizados en todos los productos. Regístrate como cliente de <span class="primary">SPARE PARTS COMANAI</span></p>
      <p>Tenemos un equipo profesional con gran experiencia que está especializado en la comercialización y distribución de suministros industriales. Solicita asesoramiento personalizado ante cualquier duda sobre las características técnicas de artículos, marcas, precios o plazos de entrega.</p>
      <p>Si eres cliente de <span class="primary">COMANAI SMARTER ENGINEERING</span>, accede al servicio de alojamiento de archivos en la nube que opera <span class="primary">SPARE PARTS COMANAI.</span></p>
      <p>El servicio permite almacenar y sincronizar planos y documentación técnica en línea en una carpeta asignada de uso exclusivo.</p>
      <p>Los archivos se sincronizan en la nube y en todas las demás computadoras del cliente de <span class="primary">SPARE PARTS COMANAI</span>.</p>
    </div>
  </div>
<!-- </div> -->
</div>
</div>
<!-- <div class="flex-3 center blue">
  <div class="row-gap">
    <img src="assets/images/Mundo1.svg" alt="Worldwide" class="img-w">
    <p>Suministramos en <span class="primary">Todo el Mundo</span></p>
  </div>
  <div class="row-gap">
    <img src="assets/images/Mundo2.svg" alt="Worldwide2" class="img-w">
    <p>Estamos ubicados en <span class="primary">España</span>, pero trabajamos con clientes de más de XX países</p>
  </div>
  <div class="row-gap">
    <img src="assets/images/Transporte.svg" alt="Worldwide transport" class="img-w">
    <p>Trabajamos con <span class="primary">clientes</span> y <span class="primary">transportistas</span> de todas partes del mundo</p>
  </div>
</div> -->
<!-- <div class="flex">
  <div class="flex-inline">
  <div>
    <img src="assets/images/Expertos.svg" alt="Expertos" class="logo-med">
  </div>
  <div>
    <p class="txt-blue">Asesoramiento de Expertos</p>
  </div>
</div>
  <div class="tex-jus">
    <p>Nuestro equipo de expertos te ayuda en tu búsqueda.</p>
    <p>Te ayudamos a localizar exactamente lo que necesites, con precio y plazo de entrega y te lo enviamos <span class="primary">a cualquier parte del mundo</span>.</p>
  </div>
</div> -->
<!-- <div class="flex-3 center grey">
  <div>
    <img src="assets/images/Referencias.svg" alt="referencias" class="logo-big">
    <p class="txt-black">Más de XXXXXXX Referencias</p>
  </div>
  <div>
    <img src="assets/images/Envios.svg" alt="Envios" class="logo-big">
    <p class="txt-black">Envíos Nacionales e Internacionales</p>
    <p><strong>(Consultar disponibilidad)</strong></p>
  </div>
  <div>
    <img src="assets/images/Fabricantes.svg" alt="Fabricantes" class="logo-big">
    <p class="txt-black">Más de XXXXXXX Fabricantes</p>
  </div>
</div> -->
<div class="center-pad">
  <div class="pad">
    <p><span class="primary txt">¿Quieres saber más?</span></p>
    <p>Contacta con nosotros</p>
  </div>
  <div class="pad">
    <p><span class="primary txt">Certificaciones</span> </p>
    <p>AENOR Empresa Registrada </p>
    <p>AENOR Gestión Ambiental</p>
    <p> IQNET </p>
  </div>
</div>

</div>
