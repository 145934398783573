import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@Services/auth/auth.service';

@Component({
  selector: 'app-login-checkout',
  templateUrl: './login-checkout.component.html',
  styleUrls: ['./login-checkout.component.scss'],
})
export class LoginCheckoutComponent implements OnInit {
  signinForm: FormGroup;
  id = localStorage.getItem('id');
  msg: string = '';
  error: string = '';

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router
  ) {
    this.signinForm = this.fb.group({
      email: [''],
      pass: [''],
    });
  }
  ngOnInit() {}

  loginUser() {
    this.authService.login(this.signinForm.value, 'checkout');
  }

  goToForgot(): void {
    this.router.navigate(['auth/forgot']);
  }
  goToRegister(): void {
    console.log('reg');
    this.router.navigate(['auth/register']);
  }
}
