import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ShareService } from '@Services/share/share.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  faChevronRight = faChevronRight;
  isLogged: boolean = false;
  lastCart: any;
  token: any;
  user: any;
  cartPhase: string = '';
  isCheckout: boolean = true;
  subscriptionUser: Subscription = new Subscription();
  subscriptionCartState: Subscription = new Subscription();

  constructor(private shareService: ShareService, private route: Router) {}

  ngOnDestroy(): void {
    this.subscriptionUser.unsubscribe();
    this.subscriptionCartState.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptionUser = this.shareService.currentUser.subscribe((arg) => {
      this.user = arg;
      if (this.user) this.isLogged = true;
    });

    this.subscriptionCartState = this.shareService.currentCartState.subscribe(
      (arg) => {
        console.log('cartphase ', arg);
        this.cartPhase = arg;
      }
    );
  }

  getCart(): void {
    console.log('get cart');
  }

  setCart(): void {
    console.log('get setCart');
    this.route.navigate(['cart/cardToBuy']);
  }

  cartComplete(): void {
    console.log('get cartComplete');
  }

  goToLogin(): void {
    this.route.navigate(['login-checkout']);
  }
  checkOut(): void {
    if (this.isCheckout) {
      this.route.navigate(['cart/checkout']);
    }
  }

  isCheckoutPhase($event: any) {
    this.isCheckout = $event;
    console.log('chek', this.isCheckout);
  }

  isLoggedAtCheckout($event: any) {
    this.isCheckout = $event.valueOf();
    console.log('checkout is ......', this.isCheckout.valueOf());
  }
}
