<div class="separator"></div>
<section class="sec-c">
  <div class="center-block-first">
  <div class="cell-container">

    <h2 class="h2">{{ 'Acceso Cliente Spare Parts' | translate}}</h2>
    <div class="auth-wrapper">
      <form class="form-signin" [formGroup]="signinForm" (ngSubmit)="loginUser()">
        <div class="form-group">
          <label for="name" >{{'Email o nombre de usuario' | translate }}*</label>
          <input
            id="name"
            type="email"
            class="form-control"
            formControlName="email"
            required
          />
        </div>
        <div class="form-group">
          <label for="pass">{{'Contraseña' | translate }}*</label>
          <input
            id="pass"
            type="password"
            class="form-control"
            formControlName="pass"
            required
          />
        </div>
        <div  class="link-text">
          <p (click)="goToForgot()" class="pointer"><strong>{{'¿Has olvidado la contraseña?' | translate }}</strong></p>
        </div>
        <button type="submit" class="btn-log">{{'ACCEDER' | translate }}</button>
      </form>
    </div>
    <!-- <p (click)="goToRegister()" class="center-link">¿No tienes cuenta de empresa? Crea una aquí</p> -->


  </div>
  <div *ngIf="error" class="error">{{error}}</div>
  <div *ngIf="msg" class="msg-ok">{{msg}}</div>

</div>
<div class="center-block-second">
  <div class="blq-grey">
    <div class="blq-grey-sub">
      <h2 class="h2">{{ '¿No tienes cuenta de empresa?' | translate}}</h2>
      <h2 class="h2">{{ 'Crea una aquí' | translate}}</h2>
      <div class="separator"></div>
      <p>{{ 'Crea una cuenta gratis en pocos pasos muy sencillos' | translate}}</p>
    </div>
    <div>
      <button class="btn-open" (click)="goToRegister()">{{ 'Abrir cuenta' | translate | uppercase}}</button>
    </div>
  </div>
</div>
</section>
