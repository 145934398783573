import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShareService } from '@Services/share/share.service';
import { Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'comanai';
  user: any = '';
  userSubscription: Subscription = this.shareService.currentUser.subscribe(
    (user: any) => {
      console.log('user app', user);
      this.user = user.id;
    }
  );

  constructor(
    private shareService: ShareService,
    private metaTagService: Meta
  ) {
    localStorage.setItem('language', 'es');
  }
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content: 'Angular SEO Integration, Music CRUD, Angular Universal',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Digamber Singh' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);
  }
}
